














































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import BillingFilterModel from '@/components/sysop/billing/BillingFilterModel'
import { vxm } from '@/store'
import { formatAmount, getPayerName, getProductName, getSiteName, getStatusClass } from '@/utils/billingUtils'

@Component
export default class BillingTableLines extends Vue {
  @Prop()
  private filters: BillingFilterModel

  @Prop()
  private submit: string

  @Prop({ required: true })
  private resellerId: number

  @Prop()
  private sites: Array<Record<string, unknown>>

  @Prop()
  private payers: Array<Record<string, unknown>>

  @Prop()
  private products: Array<Record<string, unknown>>

  private isLoading = false
  private data = []

  private sums = {
    totalAmount: 0,
  }

  private created() {
    this.onSubmitChange()
  }

  @Watch('submit')
  private onSubmitChange() {
    this.data = []
    if (!this.submit) {
      return
    }
    this.load()
  }

  private load() {
    let query = '?resellerId=' + this.resellerId
    if (this.filters.fromMonth) {
      query += '&from=' + encodeURIComponent(this.filters.fromMonth)
    }
    if (this.filters.toMonth) {
      query += '&to=' + encodeURIComponent(this.filters.toMonth)
    }
    if (this.filters.site) {
      query += '&siteId=' + encodeURIComponent(this.filters.site)
    }
    if (this.filters.payer) {
      query += '&payerId=' + encodeURIComponent(this.filters.payer)
    }
    if (this.filters.product) {
      query += '&subscriptionId=' + encodeURIComponent(this.filters.product)
    }
    if (this.filters.invoiceId) {
      query += '&invoiceId=' + encodeURIComponent(this.filters.invoiceId)
    }
    if (this.filters.invoiceStatus) {
      let value = ''
      switch (this.filters.invoiceStatus) {
        case 'invoiced':
          value = '1'
          break
        case 'not-invoiced':
          value = '0'
          break
      }
      query += '&isInvoiceCreated=' + value
    }
    this.isLoading = true
    this.$axios
      .get('/v4/sysop/billing/lines' + query)
      .then((response) => {
        for (const key in this.sums) {
          this.sums[key] = 0
        }
        this.data = response.data.data
        for (let i = 0; i < response.data.data.length; i++) {
          for (const key in this.sums) {
            this.sums[key] += parseFloat(response.data.data[i][key])
          }
        }
        this.isLoading = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Could not load data')
        this.isLoading = false
      })
  }

  private getInvoiceLink(invoiceId) {
    return {
      name: 'Sysop/Billing',
      query: {
        tab: 'invoices',
        invoiceId: invoiceId,
        resellerId: this.resellerId,
        submit: new Date().getTime() + '',
      },
    }
  }

  private getSiteName(siteId: number): string {
    return getSiteName(siteId, this.sites)
  }

  private getPayerName(payerId: number): string {
    return getPayerName(payerId, this.payers)
  }

  private getProductName(productId: number): string {
    return getProductName(productId, this.products)
  }

  private getStatusClass(item) {
    return getStatusClass(item)
  }

  // todo: optionally show decimals, and proper rounding
  private formatAmount(amount): string {
    return formatAmount(amount)
  }
}
