
























































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { vxm } from '@/store'

@Component
export default class GenerateInvoicesDialog extends Vue {
  private mode = 'payer'
  private modes = []
  private error = ''
  private isLoading = false
  private batchId: number = null
  private generatedCount: number = null
  private resellerId = null

  @Prop()
  private selection

  private created() {
    this.modes = [
      { id: 'payer,subscriptionType,month', name: 'Per payer and cost-type and month' },
      { id: 'payer,subscriptionType', name: 'Per payer and cost-type' },
      { id: 'payer,month', name: 'Per payer and month' },
      { id: 'payer', name: 'Per payer' },
    ]
  }

  private execute() {
    this.error = ''
    if (!this.hasSelection) {
      this.error = 'Nothing selected'
      return
    }
    if (!confirm('Invoices will be generated (but not yet sent). Are you sure?')) {
      return
    }
    this.isLoading = true
    this.$axios
      .post('/v4/sysop/billing/generate', {
        mode: this.mode,
        items: this.selection,
      })
      .then((response) => {
        this.batchId = response.data.data.batchId
        this.resellerId = response.data.data.resellerId
        this.generatedCount = response.data.data.count
        this.isLoading = false
      })
      .catch((err) => {
        this.error = 'Failed to generate invoices'
        vxm.alert.onAxiosError(err, this.error)
        this.isLoading = false
      })
  }

  private get hasSelection() {
    if (!this.selection || this.selection.length === 0) {
      return false
    }
    return true
  }

  private get didExecute() {
    return this.generatedCount !== null
  }

  private reload() {
    this.$emit('reload')
  }

  private gotoBatch() {
    this.$router.push(this.batchLink)
    this.$emit('close')
  }

  private get batchLink() {
    if (!this.batchId) {
      return ''
    }
    return {
      name: 'Sysop/Billing',
      query: {
        tab: 'invoices',
        resellerId: this.resellerId,
        batch: this.batchId.toString(),
        submit: new Date().getTime().toString(),
      },
    }
  }

  private selectionSummaryText(): string {
    if (!this.hasSelection) {
      return 'No items selected'
    }
    const distinctSites = {}
    const distinctMonths = {}
    let minPeriod = ''
    let maxPeriod = ''
    for (let i = 0; i < this.selection.length; i++) {
      const s = this.selection[i]
      const period = s.year + '-' + (s.month > 9 ? s.month : '0' + s.month)
      if (minPeriod === '' || minPeriod > period) {
        minPeriod = period
      }
      if (maxPeriod === '' || maxPeriod < period) {
        maxPeriod = period
      }
      if (s.siteId) {
        distinctSites[s.siteId] = 1
      }
      distinctMonths[period] = 1
    }
    const countSites = Object.keys(distinctSites).length
    const countMonths = Object.keys(distinctMonths).length
    const text = []
    if (countSites) {
      text.push(countSites + ' distinct sites')
    } else {
      text.push('All sites')
    }
    text.push(countMonths + ' months')
    let period = minPeriod
    if (maxPeriod !== minPeriod) {
      period += ' - ' + maxPeriod
    }
    text.push('in period ' + period)
    return text.join(', ')
  }
}
