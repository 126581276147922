
























































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { vxm } from '@/store'

@Component
export default class DeleteSettlementsDialog extends Vue {
  private areYouSure = false

  private error = ''
  private isLoading = false
  private response = null

  @Prop()
  private selection

  @Prop()
  private resellerId: number

  @Prop()
  private canSendToAccountingSystem: boolean

  private execute() {
    this.error = ''
    if (!this.hasSelection) {
      this.error = 'Nothing selected'
      return
    }
    if (!this.areYouSure) {
      this.error = 'Must confirm that you are sure'
      return
    }
    this.isLoading = true
    this.$axios
      .post('/v4/sysop/billing/delete-settlements', {
        resellerId: this.resellerId,
        items: this.selection,
      })
      .then((response) => {
        this.response = response.data.data
        this.isLoading = false
      })
      .catch((err) => {
        this.error = 'Failed to delete settlements'
        vxm.alert.onAxiosError(err, this.error)
        this.isLoading = false
      })
  }

  private get hasSelection() {
    if (!this.selection || this.selection.length === 0) {
      return false
    }
    return true
  }

  private reload() {
    this.$emit('reload')
  }

  private selectionSummaryText(): string {
    if (!this.hasSelection) {
      return 'No items selected'
    }
    return this.selection.length + ' settlements'
  }
}
