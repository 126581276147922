













































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import YearMonthField from '@/components/inputs/YearMonthField.vue'
import BillingFilterModel from '@/components/sysop/billing/BillingFilterModel'

@Component({
  components: {
    YearMonthField,
  },
})
export default class BillingFilter extends Vue {
  @Prop()
  private fields

  @Prop()
  private sites: Array<Record<string, unknown>>

  @Prop()
  private payers: Array<Record<string, unknown>>

  @Prop({ required: true, type: BillingFilterModel })
  private value: BillingFilterModel

  private data: BillingFilterModel = null

  @Prop()
  private invoiceStatuses

  @Prop()
  private sendStatuses

  @Prop()
  private settlementStatuses

  @Prop()
  private settlementInvoicedStatuses

  @Prop()
  private products

  private created() {
    this.data = new BillingFilterModel()
    this.onValueChange()
  }

  @Watch('value', { deep: true })
  private onValueChange() {
    if (this.value) {
      Object.assign(this.data, this.value)
    }
  }

  @Watch('data', { deep: true })
  private onDataChange() {
    this.$emit('input', this.data)
  }

  private t(text: string): string {
    return this.$t('c:site-billing-filter:' + text)
  }

  private showField(field: string): boolean {
    return this.fields === undefined || this.fields.indexOf(field) !== -1
  }

  private get sitesWithIdInName() {
    const result = []
    for (let i = 0; i < this.sites.length; i++) {
      result.push({
        id: this.sites[i].id,
        name: this.sites[i].id ? (this.sites[i].name + ' (p' + this.sites[i].id + ')') : '',
      })
    }
    return result
  }
}
