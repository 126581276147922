























































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import BillingFilterModel from '@/components/sysop/billing/BillingFilterModel'
import { vxm } from '@/store'
import { formatAmount } from '@/utils/billingUtils'

@Component
export default class BillingTableSettlementHistory extends Vue {
  @Prop()
  private filters: BillingFilterModel

  @Prop()
  private submit: string

  @Prop({ required: true })
  private resellerId: number

  @Prop()
  private select: string

  @Prop()
  private canSend: boolean

  private isLoading = false
  private data = []

  private created() {
    this.onSubmitChange()
  }

  @Watch('submit')
  private onSubmitChange() {
    this.data = []
    if (!this.submit) {
      return
    }
    this.load()
  }

  private load() {
    let query = '?resellerId=' + this.resellerId
    if (this.filters.fromMonth) {
      query += '&from=' + encodeURIComponent(this.filters.fromMonth)
    }
    if (this.filters.toMonth) {
      query += '&to=' + encodeURIComponent(this.filters.toMonth)
    }
    if (this.filters.settlementId) {
      query += '&settlementId=' + encodeURIComponent(this.filters.settlementId)
    }
    if (this.filters.settlementInvoicedStatus) {
      let value = ''
      switch (this.filters.settlementInvoicedStatus) {
        case 'invoiced':
          value = '1'
          break
        case 'not-invoiced':
          value = '0'
          break
      }
      query += '&isSettlementInvoiced=' + value
    }
    this.isLoading = true
    this.$axios
      .get('/v4/sysop/billing/settlements' + query)
      .then((response) => {
        for (let i = 0; i < response.data.data.length; i++) {
          response.data.data[i].isSelected = false
        }
        this.data = response.data.data
        this.isLoading = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Could not load data')
        this.isLoading = false
      })
  }

  private formatAmount(amount): string {
    return formatAmount(amount)
  }

  private getDetailsLink(settlementId) {
    return {
      name: 'Sysop/Billing/Settlement',
      params: {
        resellerId: this.resellerId,
        id: settlementId + '',
      },
    }
  }

  private getStatusClass(item) {
    return item.status === 'Sent' || item.status === 'Paid' || item.status === 'Dummy' ? 'status-ok' : 'status-error'
  }

  @Watch('select')
  private onSelectChange(value) {
    const action = value.split('-')[0]
    for (let i = 0; i < this.data.length; i++) {
      switch (action) {
        case 'all':
          this.data[i].isSelected = true
          break
        case 'none':
          this.data[i].isSelected = false
          break
        case 'invert':
          this.data[i].isSelected = !this.data[i].isSelected
          break
      }
    }
  }

  private get selection() {
    const selection = []
    for (let i = 0; i < this.data.length; i++) {
      const d = this.data[i]
      if (d.isSelected) {
        selection.push({
          selectionId: d.id,
        })
      }
    }
    return selection
  }

  @Watch('selection')
  private onSelectionChanged(selection) {
    this.$emit('selection', selection)
  }
}
