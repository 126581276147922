











import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

@Component
export default class BillingTabs extends Vue {
  @Prop()
  private value: string

  @Prop()
  private canSeeSettlement: boolean

  private sectionIndex = -1
  private pageIndex = -1

  private sectionObject = null
  private pageObject = null

  private sections = [
    // Defined in created()
  ]

  private pages = [
    { id: 'months', name: 'Per month', section: 'basis' },
    { id: 'month', name: 'Per month and site', section: 'basis' },
    { id: 'lines', name: 'Lines', section: 'basis' },
    { id: 'batches', name: 'Invoice batches', section: 'invoice' },
    { id: 'invoices', name: 'Invoices', section: 'invoice' },
    { id: 'settlement-months', name: 'Per month', section: 'settlement', },
    { id: 'settlement-history', name: 'Settlements', section: 'settlement' },
    { id: 'settlement-expenses', name: 'Expenses', section: 'settlement' },
    { id: 'settlement-vendors', name: 'Vendors', section: 'settlement' },
  ]

  // On change internal section tab index, update selected section obj, and reset page
  @Watch('sectionIndex')
  private onChangeSectionIndex() {
    const section = this.sections[this.sectionIndex]
    if (!this.sectionObject || section.id !== this.sectionObject.id) {
      this.sectionObject = section
      this.pageIndex = 0
    }
  }

  // On change internal page tab index, update selected page obj, and emit id
  @Watch('pageIndex')
  private onChangePageIndex() {
    const pages = this.getPagesForSection(this.sectionObject.id) || []
    const page = pages[this.pageIndex]
    if (!page) {
      return
    }
    if (!this.pageObject || page.id !== this.pageObject.id) {
      this.pageObject = page
      this.$emit('input', page.id)
    }
  }

  // On change external page id (section + page), set internal values
  @Watch('value')
  private onValueChange() {
    const pageId = this.value

    // Get section ID

    let sectionId = ''
    for (let i = 0; i < this.pages.length; i++) {
      if (this.pages[i].id === pageId) {
        sectionId = this.pages[i].section
        break
      }
    }

    // Get section index
    let sectionIndex = -1
    for (let i = 0; i < this.sections.length; i++) {
      if (this.sections[i].id === sectionId) {
        sectionIndex = i
        break
      }
    }

    // Get page index

    let pageIndex = -1
    const pagesForSection = this.getPagesForSection(sectionId)
    for (let i = 0; i < pagesForSection.length; i++) {
      if (pagesForSection[i].id === pageId) {
        pageIndex = i
        break
      }
    }

    // Update

    // prevent onChangeSectionIndex() from firing, and resetting pageIndex before us
    this.sectionObject = this.sections[sectionIndex]

    // then update the indexes, only onChangePageIndex() will fire
    this.sectionIndex = sectionIndex
    this.pageIndex = pageIndex

  }

  private created() {
    this.sections = [
      { id: 'basis', name: 'Generate' },
      { id: 'invoice', name: 'Send' },
    ]
    if (this.canSeeSettlement) {
      this.sections.push({ id: 'settlement', name: 'Settle' })
    }
    this.onValueChange()
  }

  private get pagesForSection() {
    if (!this.sectionObject) {
      return []
    }
    return this.getPagesForSection(this.sectionObject.id)
  }

  private getPagesForSection(sectionId: string) {
    const result = []
    for (const page of this.pages) {
      if (page.section !== sectionId) {
        continue
      }
      result.push(page)
    }
    return result
  }
}
