























































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { vxm } from '@/store'

@Component
export default class SettleDialog extends Vue {
  private subscriptionTypeCategories = []
  private subscriptionTypeCategory = ''
  private error = ''
  private isLoading = false
  private generatedCount: number = null

  @Prop()
  private selection

  private created() {
    this.subscriptionTypeCategories = [
      { id: '', name: 'Both' },
      { id: 'Fixed', name: 'Fixed' },
      { id: 'Dynamic', name: 'Dynamic' },
    ]
  }

  private execute() {
    this.error = ''
    if (!this.hasSelection) {
      this.error = 'Nothing selected'
      return
    }
    this.isLoading = true
    this.$axios
      .post('/v4/sysop/billing/settle', {
        subscriptionTypeCategory: this.subscriptionTypeCategory || null,
        items: this.selection,
      })
      .then((response) => {
        this.generatedCount = response.data.data.count
        this.isLoading = false
      })
      .catch((err) => {
        this.error = 'Failed to generate settlements'
        vxm.alert.onAxiosError(err, this.error)
        this.isLoading = false
      })
  }

  private get hasSelection() {
    if (!this.selection || this.selection.length === 0) {
      return false
    }
    return true
  }

  private get didExecute() {
    return this.generatedCount !== null
  }

  private reload() {
    this.$emit('reload')
  }

  private selectionSummaryText(): string {
    if (!this.hasSelection) {
      return 'No items selected'
    }
    return this.selection.length + ' months'
  }
}
