import { render, staticRenderFns } from "./BillingTableSettlementMonths.vue?vue&type=template&id=788c2a72&scoped=true&"
import script from "./BillingTableSettlementMonths.vue?vue&type=script&lang=ts&"
export * from "./BillingTableSettlementMonths.vue?vue&type=script&lang=ts&"
import style0 from "./BillingTableSettlementMonths.vue?vue&type=style&index=0&id=788c2a72&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "788c2a72",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCheckbox,VIcon,VSimpleTable})
