






























































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import BillingTabs from '@/components/sysop/billing/BillingTabs.vue'
import BillingFilter from '@/components/sysop/billing/BillingFilter.vue'
import BillingFilterModel from '@/components/sysop/billing/BillingFilterModel'
import { Watch } from 'vue-property-decorator'
import BillingTableMonths from '@/components/sysop/billing/BillingTableMonths.vue'
import BillingTableMonth from '@/components/sysop/billing/BillingTableMonth.vue'
import BillingTableLines from '@/components/sysop/billing/BillingTableLines.vue'
import BillingTableBatches from '@/components/sysop/billing/BillingTableBatches.vue'
import BillingTableInvoices from '@/components/sysop/billing/BillingTableInvoices.vue'
import BillingTableExpenses from '@/components/sysop/billing/BillingTableExpenses.vue'
import BillingTableExpenseVendors from '@/components/sysop/billing/BillingTableExpenseVendors.vue'
import BillingTableSettlementMonths from '@/components/sysop/billing/BillingTableSettlementMonths.vue'
import BillingTableSettlementHistory from '@/components/sysop/billing/BillingTableSettlementHistory.vue'
import GenerateInvoicesDialog from '@/components/sysop/billing/GenerateInvoicesDialog.vue'
import SendCustomerInvoicesDialog from '@/components/sysop/billing/SendCustomerInvoicesDialog.vue'
import SendSettlementInvoicesDialog from '@/components/sysop/billing/SendSettlementInvoicesDialog.vue'
import SettleDialog from '@/components/sysop/billing/SettleDialog.vue'
import DeleteSettlementsDialog from '@/components/sysop/billing/DeleteSettlementsDialog.vue'
import { vxm } from '@/store'
import b64toBlob from 'b64-to-blob'

@Component({
  components: {
    BillingTabs,
    BillingFilter,
    BillingTableMonths,
    BillingTableMonth,
    BillingTableLines,
    BillingTableBatches,
    BillingTableInvoices,
    BillingTableExpenseVendors,
    BillingTableExpenses,
    BillingTableSettlementMonths,
    BillingTableSettlementHistory,
    GenerateInvoicesDialog,
    SendCustomerInvoicesDialog,
    SendSettlementInvoicesDialog,
    SettleDialog,
    DeleteSettlementsDialog,
  },
})
export default class Main extends Vue {
  private tab = 'months'
  private filters: BillingFilterModel = null
  private resellerId = null
  private isLoading = true
  private selectTrigger = ''
  private selection = []
  private showGenerateInvoicesDialog: number = null
  private showSendCustomerInvoicesDialog: number = null
  private showSendSettlementInvoicesDialog: number = null
  private showDeleteSettlementsDialog: number = null
  private showSettleDialog: number = null
  private resellers = []
  private vendors = []
  private payers = []
  private sites = []
  private products = []

  // Feature toggles (access)

  private canGenerateInvoices = false
  private canSendInvoicesToAccounting = false
  private canSendInvoicesAsDummy = false
  private canSeeSettlement = false
  private canCreateSettlement = false
  private canSendSettlement = false
  private canDeleteSettlement = false
  private canWriteExpenses = false
  private canWriteVendors = false

  private invoiceStatuses = [
    { id: '', name: '' },
    { id: 'not-invoiced', name: 'Not generated' },
    { id: 'invoiced', name: 'Generated' },
  ]

  private sendStatuses = [
    { id: '', name: '' },
    { id: 'not-sent', name: 'Not sent' },
    { id: 'sent', name: 'Sent' },
  ]

  private settlementStatuses = [
    { id: '', name: '' },
    { id: 'not-settled', name: 'Not settled' },
    { id: 'settled', name: 'Settled' },
  ]

  private settlementInvoicedStatuses = [
    { id: '', name: '' },
    { id: 'not-invoiced', name: 'Not invoiced' },
    { id: 'invoiced', name: 'Invoiced' },
  ]

  private typesOfCost = [
    { id: '', name: '' },
    { id: 'static', name: 'Fixed costs' },
    { id: 'dynamic', name: 'Variable costs' },
  ]

  private created() {
    this.filters = new BillingFilterModel()
    this.onRouteChange()
    this.loadFeatures().then(() => {
      this.loadResellers().then(() => {
        if (!this.resellerId && this.resellerOptions && this.resellerOptions.length === 2) {
          for (let i = 0; i < this.resellerOptions.length; i++) {
            const r = this.resellers[i]
            if (r.id) {
              this.resellerId = r.id
              this.reloadResellerRelatedData()
              this.navigate(false)
              break
            }
          }
        } else {
          this.isLoading = false
        }
      })
    })
  }

  private get resellerIdInput() {
    return this.resellerId
  }

  private set resellerIdInput(value) {
    this.navigate(false, '', { resellerId: value })
  }

  private reloadResellerRelatedData() {
    if (!this.resellerId) {
      return
    }
    this.isLoading = true
    this.loadPayers().then(() => {
      this.loadSites().then(() => {
        this.loadProducts().then(() => {
          this.loadVendors().then(() => {
            this.isLoading = false
          })
        })
      })
    })
  }

  @Watch('$route', { deep: true })
  private onRouteChange() {
    const resellerIdAfter = this.$route.query?.resellerId ? parseInt(this.$route.query.resellerId as string) : null
    const resellerIdBefore = this.resellerId
    this.filters.populate(this.$route.query)
    if (this.$route.query?.tab) {
      this.tab = this.$route.query.tab as string
    }
    if (resellerIdAfter !== resellerIdBefore) {
      this.resellerId = resellerIdAfter
      this.reloadResellerRelatedData()
    }
  }

  private onTabChange(tab) {
    if (tab !== this.tab) {
      // this.navigate(!!this.$route.query?.submit, tab)
      this.navigate(false, tab)
    }
  }

  private clickUpdate() {
    this.navigate(true)
  }

  private clickReset() {
    this.filters.reset()
    this.navigate(false)
  }

  private clickToExcel() {
    if (!this.selection || this.selection.length === 0) {
      vxm.alert.error({
        content: 'Nothing selected',
      })
      return
    }
    this.$axios
      .post('/v4/sysop/billing/to-excel', {
        resellerId: this.resellerId,
        items: this.selection,
      })
      .then((response) => {
        const filename = 'BillingTool.xlsx'
        const blob = b64toBlob(response.data.data.data, 'application/vnd.ms-excel')
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed to generate excel file')
      })
  }

  private clickTodo() {
    this.filters.reset()
    switch (this.tab) {
      case 'months':
      case 'month':
        this.filters.invoiceStatus = 'not-invoiced'
        break
      case 'batches':
      case 'invoices':
        this.filters.sendStatus = 'not-sent'
        break
      case 'settlement-months':
        this.filters.settlementStatus = 'not-settled'
        break
      case 'settlement-history':
        this.filters.settlementInvoicedStatus = 'not-invoiced'
        break
    }
    this.navigate(true)
  }

  private get canSeeButtonTodo() {
    switch (this.tab) {
      case 'months':
      case 'batches':
      case 'settlement-months':
      case 'settlement-history':
        return true
    }
    return false
  }

  private navigate(isSubmit: boolean, tab = '', overrides = {}) {
    const query = {
      submit: '',
      tab: tab || this.tab,
      resellerId: this.resellerId + '',
    }
    if (isSubmit) {
      query.submit = '' + new Date().getTime()
    }
    for (const key in this.filters) {
      query[key] = this.filters[key] || ''
    }
    if (overrides) {
      for (const key in overrides) {
        query[key] = overrides[key]
      }
    }
    this.$router.push({
      name: 'Sysop/Billing',
      query: query,
    })
  }

  private reload() {
    this.showGenerateInvoicesDialog = null
    this.showSendCustomerInvoicesDialog = null
    this.showSendSettlementInvoicesDialog = null
    this.showDeleteSettlementsDialog = null
    this.showSettleDialog = null
    this.navigate(true)
  }

  private loadFeatures() {
    return new Promise((resolve, reject) => {
      this.$axios
        .get('/v4/sysop/billing/features')
        .then((response) => {
          for (let i = 0; i < response.data.data.length; i++) {
            if (!response.data.data[i].enabled) {
              continue
            }
            switch (response.data.data[i].feature) {
              case 'CanGenerateInvoices':
                this.canGenerateInvoices = true
                break
              case 'CanSendInvoicesToAccounting':
                this.canSendInvoicesToAccounting = true
                break
              case 'CanSendInvoicesAsDummy':
                this.canSendInvoicesAsDummy = true
                break
              case 'CanSeeSettlement':
                this.canSeeSettlement = true
                break
              case 'CanCreateSettlement':
                this.canCreateSettlement = true
                break
              case 'CanSendSettlement':
                this.canSendSettlement = true
                break
              case 'CanDeleteSettlement':
                this.canDeleteSettlement = true
                break
              case 'CanWriteExpenses':
                this.canWriteExpenses = true
                break
              case 'CanWriteVendors':
                this.canWriteVendors = true
                break
            }
          }
          resolve()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Error loading features')
          reject(err)
        })
    })
  }

  private loadResellers() {
    return new Promise((resolve, reject) => {
      this.$axios
        .get('/v4/sysop/billing/resellers')
        .then((response) => {
          this.resellers = response.data.data
          resolve()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Error loading resellers')
          reject(err)
        })
    })
  }

  private get resellerOptions() {
    const result = []
    for (let i = 0; i < this.resellers.length; i++) {
      if (!this.resellers[i].hasAccess) {
        continue
      }
      result.push(this.resellers[i])
    }
    return result
  }

  private loadVendors() {
    return new Promise((resolve, reject) => {
      this.$axios
        .get('/v4/sysop/billing/expense-vendors')
        .then((response) => {
          this.vendors = this.addEmptyOption(response.data.data)
          resolve()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Error loading vendors')
          reject(err)
        })
    })
  }

  private loadPayers() {
    return new Promise((resolve, reject) => {
      this.$axios
        .get('/v4/sysop/billing/payers?resellerId=' + this.resellerId)
        .then((response) => {
          this.payers = this.addEmptyOption(response.data.data)
          resolve()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Error loading payers')
          reject(err)
        })
    })
  }

  private loadSites() {
    return new Promise((resolve, reject) => {
      this.$axios
        .get('/v4/sysop/billing/sites?resellerId=' + this.resellerId)
        .then((response) => {
          this.sites = this.addEmptyOption(response.data.data)
          resolve()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Error loading sites')
          reject(err)
        })
    })
  }

  private loadProducts() {
    return new Promise((resolve, reject) => {
      this.$axios
        .get('/v4/sysop/billing/subscriptions?resellerId=' + this.resellerId)
        .then((response) => {
          this.products = this.addEmptyOption(response.data.data)
          resolve()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Error loading products')
          reject(err)
        })
    })
  }

  private addEmptyOption(items) {
    const result = [{ id: null, name: '' }]
    for (let i = 0; i < items.length; i++) {
      result.push(items[i])
    }
    return result
  }

  private openGenerateInvoicesDialog() {
    this.showGenerateInvoicesDialog = new Date().getTime()
  }

  private openSendCustomerInvoicesDialog() {
    this.showSendCustomerInvoicesDialog = new Date().getTime()
  }

  private openSettleDialog() {
    this.showSettleDialog = new Date().getTime()
  }

  private t(text: string): string {
    return this.$t('c:site-billing:' + text)
  }

  private get showFilters() {
    switch (this.tab) {
      case 'months':
        return ['period', 'invoiceStatus']
      case 'month':
        return ['period', 'invoiceStatus', 'payer', 'site']
      case 'lines':
        return ['period', 'invoiceStatus', 'payer', 'site', 'product', 'invoiceId']
      case 'batches':
        return ['batch', 'period', 'sendStatus']
      case 'invoices':
        return ['batch', 'period', 'sendStatus', 'payer', 'invoiceId']
      case 'settlement-months':
        return ['period', 'settlementStatus']
      case 'settlement-history':
        return ['period', 'settlementId', 'settlementInvoicedStatus']
      default:
        return []
    }
  }

  private get canSeeSelection() {
    switch (this.tab) {
      case 'batches':
      case 'invoices':
      case 'settlement-months':
      case 'settlement-history':
        return true
    }
    return false
  }

  private get canSeeButtons() {
    switch (this.tab) {
      case 'months':
      case 'month':
      case 'lines':
      case 'batches':
      case 'invoices':
      case 'settlement-months':
      case 'settlement-history':
        return true
    }
    return false
  }

  private get canSeeButtonGenerateInvoices() {
    if (!this.canGenerateInvoices) {
      return false
    }
    if (!this.$route.query?.submit) {
      return false
    }
    if (this.selection.length === 0) {
      return false
    }
    switch (this.tab) {
      case 'months':
      case 'month':
        return true
    }
    return false
  }

  private get canSeeButtonSendCustomerInvoices() {
    if (!this.canSendInvoicesToAccounting && !this.canSendInvoicesAsDummy) {
      return false
    }
    return this.hasSelectedInvoicesToSend
  }

  private get canSeeButtonExcel() {
    return this.hasSelectedInvoicesToSend
  }

  private get hasSelectedInvoicesToSend() {
    if (!this.$route.query?.submit) {
      return false
    }
    if (this.selection.length === 0) {
      return false
    }
    switch (this.tab) {
      case 'batches':
      case 'invoices':
        return true
    }
    return false
  }

  private get canSeeButtonSettle() {
    if (!this.canCreateSettlement) {
      return false
    }
    if (!this.$route.query?.submit) {
      return false
    }
    if (this.selection.length === 0) {
      return false
    }
    switch (this.tab) {
      case 'settlement-months':
        return true
    }
    return false
  }

  private get canSeeButtonSendSettlementInvoices() {
    if (!this.canSendSettlement) {
      return false
    }
    if (!this.$route.query?.submit) {
      return false
    }
    if (this.selection.length === 0) {
      return false
    }
    switch (this.tab) {
      case 'settlement-history':
        return true
    }
    return false
  }

  private get canSeeButtonDeleteSettlements() {
    if (!this.canDeleteSettlement) {
      return false
    }
    if (!this.$route.query?.submit) {
      return false
    }
    if (this.selection.length === 0) {
      return false
    }
    switch (this.tab) {
      case 'settlement-history':
        return true
    }
    return false
  }

  private openDeleteSettlementsDialog() {
    this.showDeleteSettlementsDialog = new Date().getTime()
  }

  private openSendSettlementInvoicesDialog() {
    this.showSendSettlementInvoicesDialog = new Date().getTime()
  }

  private get submit() {
    return (this.$route.query?.submit as string) || ''
  }

  private selectAll() {
    this.setSelectTrigger('all')
  }

  private selectNone() {
    this.setSelectTrigger('none')
  }

  private selectInvert() {
    this.setSelectTrigger('invert')
  }

  private setSelectTrigger(value: string) {
    this.selectTrigger = value + '-' + new Date().getTime()
  }

  private onChangeSelection(selection) {
    this.selection = selection
  }
}
