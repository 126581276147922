































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import BillingFilterModel from '@/components/sysop/billing/BillingFilterModel'
import { vxm } from '@/store'
import { formatAmount, getPayerName } from '@/utils/billingUtils'

@Component
export default class BillingTableInvoices extends Vue {
  @Prop()
  private filters: BillingFilterModel

  @Prop()
  private submit: string

  @Prop({ required: true })
  private resellerId: number

  @Prop({ required: true })
  private payers

  @Prop()
  private select: string

  private isLoading = false
  private data = []
  private sums = {
    fixedAmount: 0,
    dynamicAmount: 0,
    totalAmount: 0,
    invoiceSentAmount: 0,
  }

  private created() {
    this.onSubmitChange()
  }

  @Watch('submit')
  private onSubmitChange() {
    this.data = []
    if (!this.submit) {
      return
    }
    this.load()
  }

  private load() {
    let query = '?resellerId=' + this.resellerId
    if (this.filters.fromMonth) {
      query += '&from=' + encodeURIComponent(this.filters.fromMonth)
    }
    if (this.filters.toMonth) {
      query += '&to=' + encodeURIComponent(this.filters.toMonth)
    }
    if (this.filters.batch) {
      query += '&batchId=' + encodeURIComponent(this.filters.batch)
    }
    if (this.filters.payer) {
      query += '&payerId=' + encodeURIComponent(this.filters.payer)
    }
    if (this.filters.invoiceId) {
      query += '&ourInvoiceNumber=' + encodeURIComponent(this.filters.invoiceId)
    }
    if (this.filters.sendStatus) {
      let value = ''
      switch (this.filters.sendStatus) {
        case 'sent':
          value = '1'
          break
        case 'not-sent':
          value = '0'
          break
      }
      query += '&isInvoiceSent=' + value
    }
    this.isLoading = true
    this.$axios
      .get('/v4/sysop/billing/invoices' + query)
      .then((response) => {
        for (const key in this.sums) {
          this.sums[key] = 0
        }
        for (let i = 0; i < response.data.data.length; i++) {
          for (const key in this.sums) {
            this.sums[key] += parseFloat(response.data.data[i][key])
          }
          response.data.data[i].isSelected = false
        }
        this.data = response.data.data
        this.isLoading = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Could not load data')
        this.isLoading = false
      })
  }

  private getStatusClass(item) {
    return item.status === 'Sent' ? 'status-ok' : 'status-error'
  }

  // todo: optionally show decimals, and proper rounding
  private formatAmount(amount): string {
    return formatAmount(amount)
  }

  private getPayerName(payerId: number): string {
    return getPayerName(payerId, this.payers)
  }

  private getDetailsUrl(item) {
    return {
      name: 'Sysop/Billing',
      query: {
        tab: 'lines',
        resellerId: this.resellerId,
        invoiceId: item.id,
        submit: '1',
      },
    }
  }

  @Watch('select')
  private onSelectChange(value) {
    const action = value.split('-')[0]
    for (let i = 0; i < this.data.length; i++) {
      switch (action) {
        case 'all':
          this.data[i].isSelected = true
          break
        case 'none':
          this.data[i].isSelected = false
          break
        case 'invert':
          this.data[i].isSelected = !this.data[i].isSelected
          break
        case 'fixed':
          this.data[i].isSelected = parseFloat(this.data[i].fixedAmount) > 0
          break
      }
    }
  }

  private get selection() {
    const selection = []
    for (let i = 0; i < this.data.length; i++) {
      const d = this.data[i]
      if (d.isSelected) {
        selection.push({
          invoiceId: d.id,
        })
      }
    }
    return selection
  }

  @Watch('selection')
  private onSelectionChanged(selection) {
    this.$emit('selection', selection)
  }
}
