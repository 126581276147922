




















































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'
import { Prop } from 'vue-property-decorator'
import pdf from 'pdfvuer'

@Component({
  components: {
    pdf,
  },
})
export default class BillingTableExpenses extends Vue {
  @Prop()
  private resellerId

  @Prop()
  private resellers

  @Prop()
  private vendors

  @Prop()
  private canWrite

  private data = []
  private isLoading = false
  private dialog = null
  private dialogDefaults = null
  private dialogData = null
  private dialogNextId = 0
  private dialogError = ''
  private invoiceDialog = null
  private invoiceDialogData = null
  private invoiceDialogLoading = null
  private invoiceDialogPdfData = ''
  private invoiceDialogPdfPage = 1
  private invoiceDialogPdfPages = 1

  private created() {
    this.load()
    if (this.$route.query.openFromParams) {
      const r = this.$route.query.resellerId || null
      const y = this.$route.query.year || null
      const m = this.$route.query.month || null
      if (r && y && m) {
        this.clickEdit({
          resellerId: r,
          year: y,
          month: m,
        })
      }
    }
  }

  private load() {
    this.isLoading = true
    this.$axios
      .get('/v4/sysop/billing/expenses/per-month?resellerId=' + this.resellerId)
      .then((response) => {
        this.data = response.data.data
        this.isLoading = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error loading expenses per month')
        this.isLoading = false
      })
  }

  private clickEdit(item) {
    this.dialog = new Date().getTime()
    this.dialogDefaults = {
      year: item.year,
      month: item.month,
      billingResellerId: this.resellerId,
    }
    this.isLoading = true
    this.$axios
      .get(
        '/v4/sysop/billing/expenses/for-month?resellerId=' +
          this.resellerId +
          '&year=' +
          item.year +
          '&month=' +
          item.month +
          '&suggestDefaults=1',
      )
      .then((response) => {
        for (let i = 0; i < response.data.data.length; i++) {
          response.data.data[i].isDeleted = false
        }
        this.dialogData = response.data.data
        this.isLoading = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error loading expenses for month')
        this.isLoading = false
      })
  }

  private get resellersWithEmptyOption() {
    const result = []
    result.push({ id: -1, name: '' })
    for (let i = 0; i < this.resellers.length; i++) {
      if (!this.resellers[i].id) {
        continue
      }
      result.push(this.resellers[i])
    }
    return result
  }

  private dialogAddRow() {
    this.dialogNextId--
    this.dialogData.push({
      id: this.dialogNextId,
      year: this.dialogDefaults.year,
      month: this.dialogDefaults.month,
      billingResellerId: this.dialogDefaults.billingResellerId,
      isDeleted: false,
    })
  }

  private dialogDeleteRow(item) {
    item.isDeleted = !item.isDeleted
  }

  private canShowInvoice(item) {
    return item.vendorId && item.vendorInvoiceNumber
  }

  private onInvoiceDialogPdfPagesChanged(v) {
    console.log('p:', v)
    this.invoiceDialogPdfPages = v
  }

  private showInvoice(item) {
    this.invoiceDialogPdfPage = 1
    this.invoiceDialogPdfPages = 1
    this.invoiceDialogPdfData = ''
    this.invoiceDialogData = item
    this.invoiceDialogLoading = true
    this.invoiceDialog = new Date().getTime()
    // tuba
    this.$axios
      .get(
        '/v4/sysop/billing/expenses/supplier-invoices/' +
          encodeURIComponent(item.vendorId) +
          '/' +
          encodeURIComponent(item.vendorInvoiceNumber),
      )
      .then((response) => {
        this.invoiceDialogPdfData = 'data:application/pdf;base64,' + response.data.data.contents
        this.invoiceDialogLoading = false
      })
      .catch((err) => {
        console.error('Error fetching expense invoice:', err)
        this.invoiceDialogLoading = false
      })
  }

  private dialogSave() {
    const payload = []

    this.dialogError = ''
    for (let i = 0; i < this.dialogData.length; i++) {
      const item = Object.assign({}, this.dialogData[i])
      if (item.id < 1) {
        delete item.id // new items have fake neg. ids to satisfy demand for :key in vue gui
      }
      if (item.isDeleted && !item.id) {
        continue
      }
      if (!item.vendorId) {
        this.dialogError = 'Must choose a vendor for all rows'
        return
      }
      /* Nah.. It will simply have "incomplete" status until it's done
      if (!item.amount) {
        this.dialogError = 'Must choose an amount for all rows'
        return
      }
      */
      payload.push(item)
    }

    this.isLoading = true
    this.$axios
      .post('/v4/sysop/billing/expenses/update-many', { items: payload })
      .then((_response) => {
        this.isLoading = false
        this.dialog = false
        this.load()
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error saving expenses for month')
        this.isLoading = false
      })
  }

  private getVendorName(id) {
    for (let i = 0; i < this.vendors.length; i++) {
      const v = this.vendors[i]
      if (v.id == id) {
        return v.name
      }
    }
    return ''
  }
}
