





































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'
import { Prop } from 'vue-property-decorator'

@Component
export default class BillingExpenseVendors extends Vue {
  private data = []
  private resellers = []
  private isLoading = false
  private dialog = null
  private dialogDefaults = null
  private dialogData = null
  private dialogError = ''
  private resellerRequirements = []

  @Prop()
  private canWrite

  private resellerVendorRequirementOptions = [
    { id: null, name: '' },
    { id: true, name: 'Required' },
    { id: false, name: 'Not required' },
  ]

  private created() {
    this.load()
  }

  private load() {
    this.loadResellers(() => {
      this.loadVendors()
    })
  }

  private loadVendors() {
    this.isLoading = true
    this.$axios
      .get('/v4/sysop/billing/expense-vendors')
      .then((response) => {
        this.data = response.data.data
        for (let i = 0; i < response.data.data.length; i++) {
          this.makeVirtualResellerRequirements(response.data.data[i])
        }
        this.isLoading = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error loading vendors')
        this.isLoading = false
      })
  }

  private loadResellers(callback) {
    this.isLoading = true
    this.$axios
      .get('/v4/sysop/billing/resellers')
      .then((response) => {
        this.resellers = response.data.data
        this.isLoading = false
        if (callback) {
          callback()
        }
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error loading resellers')
        this.isLoading = false
      })
  }

  private clickEdit(item) {
    this.dialog = new Date().getTime()
    this.dialogData = {
      id: 0,
      resellerRequirements: [],
    }
    if (item?.id) {
      Object.assign(this.dialogData, item)
    } else {
      this.makeVirtualResellerRequirements(this.dialogData)
    }
  }

  /**
   * On reseller we have operatingExpenseVendorRequirements,
   * which is a map of which vendors that reseller require to do dynamic settlement.
   *
   * It's structured like this: {
   *  vendorId1: null,  // unknown
   *  vendorId2: true,  // required
   *  vendorId3: false, // not required
   * }
   *
   * To make it easy to work with this in GUI we create a
   * corresponding structure "resellerRequirements" on vendor instead: {
   *  resellerId1: null,
   *  resellerId2: true,
   * }
   *
   * On save we use this to update resellers too, and then reload everything.
   */
  private makeVirtualResellerRequirements(item) {
    const result = []
    if (this.resellers) {
      for (let i = 0; i < this.resellers.length; i++) {
        const reseller = this.resellers[i]
        if (reseller.isSupplier) {
          continue
        }
        result.push({
          resellerId: reseller.id,
          resellerName: reseller.name,
          requirement: reseller.operatingExpenseVendorRequirements[item.id],
        })
      }
    }
    item.resellerRequirements = result
  }

  private clickDelete(item) {
    if (!confirm('Are you sure?')) {
      return
    }
    this.isLoading = true
    this.$axios
      .delete('/v4/sysop/billing/expense-vendors/' + item.id)
      .then((_response) => {
        this.load()
        this.$emit('updated')
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed to delete vendor')
        this.isLoading = false
      })
  }

  private dialogSave() {
    this.dialogError = ''

    const item = Object.assign({}, this.dialogData)
    if (!item.name) {
      this.dialogError = 'Must enter name'
      return
    }

    this.isLoading = true
    this.$axios
      .post('/v4/sysop/billing/expense-vendors', item)
      .then((_response) => {
        this.isLoading = false
        this.dialog = false
        this.load()
        this.$emit('updated')
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error saving vendor')
        this.isLoading = false
      })
  }

  private getResellerName(id) {
    for (let i = 0; i < this.resellers.length; i++) {
      if (this.resellers[i].id == id) {
        return this.resellers[i].name
      }
    }
  }
}
