


































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { vxm } from '@/store'

@Component
export default class SendCustomerInvoicesDialog extends Vue {
  private mode = ''
  private modes = []

  private error = ''
  private isLoading = false
  private response = null
  private sentCount: number = null

  @Prop()
  private selection

  @Prop()
  private resellerId: number

  @Prop()
  private canSendToAccountingSystem: boolean

  @Prop()
  private canSendAsDummy: boolean

  private mounted() {
    this.modes = [{ id: '', name: '' }]
    if (this.canSendAsDummy) {
      this.modes.push({ id: 'Manual', name: 'Just mark the invoices as sent' })
    }
    if (this.canSendToAccountingSystem) {
      this.modes.push({ id: 'Integration', name: 'Send to accounting system' })
    }
  }

  private execute() {
    this.error = ''
    if (!this.hasSelection) {
      this.error = 'Nothing selected'
      return
    }
    if (!this.mode) {
      this.error = 'Must select how to send'
      return
    }
    const warning =
      this.mode === 'Integration'
        ? 'Invoices will be CREATED IN ACCOUNTING SYSTEM and marked as sent. Are you sure?'
        : 'Invoices will be marked as sent. Are you sure?'
    if (!confirm(warning)) {
      return
    }
    this.isLoading = true
    this.$axios
      .post('/v4/sysop/billing/send-to-customer', {
        mode: this.mode,
        resellerId: this.resellerId,
        items: this.selection,
      })
      .then((response) => {
        this.response = response.data.data
        this.isLoading = false
      })
      .catch((err) => {
        this.error = 'Failed to send invoices'
        vxm.alert.onAxiosError(err, this.error)
        this.isLoading = false
      })
  }

  private get hasSelection() {
    if (!this.selection || this.selection.length === 0) {
      return false
    }
    return true
  }

  private get didExecute() {
    return this.sentCount !== null
  }

  private reload() {
    this.$emit('reload')
  }

  private selectionSummaryText(): string {
    if (!this.hasSelection) {
      return 'No items selected'
    }
    const distinctBatches = {}
    const distinctInvoices = {}
    for (let i = 0; i < this.selection.length; i++) {
      const s = this.selection[i]
      if (s.batchId) {
        distinctBatches[s.batchId] = 1
      }
      if (s.invoiceId) {
        distinctInvoices[s.invoiceId] = 1
      }
    }
    const countBatches = Object.keys(distinctBatches).length
    const countInvoices = Object.keys(distinctInvoices).length
    const text = []
    if (countBatches) {
      text.push('All invoices in ' + countBatches + ' batches')
    }
    if (countInvoices) {
      text.push(countInvoices + ' individual invoices')
    }
    return text.join(' and ')
  }
}
