




// When using this, make sure you implement validation on calling view, to not allow proceeding without a valid time
//   for example you can check at saveRule() of src/views/booking/settings/schedule/Edit.vue
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { mask } from 'vue-the-mask'

@Component({
  directives: {
    mask,
  },
})
export default class YearMonthField extends Vue {
  @Prop({ type: [String, Array] })
  private value: string | string[]

  private rules = []

  private get input() {
    return this.value
  }

  private set input(value) {
    this.$emit('input', value)
  }

  private mounted() {
    this.rules = [
      (v) => {
        const invalid = this.$t('Invalid month (YYYY-MM)')
        if (!v) {
          return true
        }
        if (!/^\d\d\d\d-\d\d$/.test(v)) {
          return invalid
        }
        const parts = v.split('-')
        const year = parts[0]
        const month = parseInt(parts[1])
        if (year < 1000 || year > 9999 || month < 1 || month > 12) {
          return invalid
        }
        return true
      },
    ]
  }
}
