

































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { vxm } from '@/store'

@Component
export default class SendSettlementInvoicesDialog extends Vue {
  private mode = ''
  private modes = []

  private error = ''
  private isLoading = false
  private response = null

  @Prop()
  private selection

  @Prop()
  private resellerId: number

  @Prop()
  private canSendToAccountingSystem: boolean

  private mounted() {
    this.modes = [
      { id: '', name: '' },
      { id: 'Manual', name: 'Just mark the settlements as sent' },
    ]
    if (this.canSendToAccountingSystem) {
      this.modes.push({ id: 'Integration', name: 'Send to accounting system' })
    }
  }

  private execute() {
    this.error = ''
    if (!this.hasSelection) {
      this.error = 'Nothing selected'
      return
    }
    if (!this.mode) {
      this.error = 'Must select how to send'
      return
    }
    const warning =
      this.mode === 'Integration'
        ? 'Invoices for settlements will be CREATED IN ACCOUNTING SYSTEM and marked as sent. Are you sure?'
        : 'Settlements will be marked as sent. Are you sure?'
    if (!confirm(warning)) {
      return
    }
    this.isLoading = true
    this.$axios
      .post('/v4/sysop/billing/invoice-settlements', {
        mode: this.mode,
        resellerId: this.resellerId,
        items: this.selection,
      })
      .then((response) => {
        this.response = response.data.data
        this.isLoading = false
      })
      .catch((err) => {
        this.error = 'Failed to invoice settlements'
        vxm.alert.onAxiosError(err, this.error)
        this.isLoading = false
      })
  }

  private get hasSelection() {
    if (!this.selection || this.selection.length === 0) {
      return false
    }
    return true
  }

  private reload() {
    this.$emit('reload')
  }

  private selectionSummaryText(): string {
    if (!this.hasSelection) {
      return 'No items selected'
    }
    return this.selection.length + ' settlements'
  }
}
