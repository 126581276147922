




































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import BillingFilterModel from '@/components/sysop/billing/BillingFilterModel'
import { vxm } from '@/store'
import { formatAmount } from '@/utils/billingUtils'

@Component
export default class BillingTableSettlementMonths extends Vue {
  @Prop()
  private filters: BillingFilterModel

  @Prop()
  private submit: string

  @Prop({ required: true })
  private resellerId: number

  @Prop()
  private select: string

  @Prop()
  private canSettle: boolean

  private data = []
  private isLoading = false

  private created() {
    this.onSubmitChange()
  }

  @Watch('submit')
  private onSubmitChange() {
    this.data = []
    if (!this.submit) {
      return
    }
    this.load()
  }

  private load() {
    let query = '?resellerId=' + this.resellerId
    if (this.filters.fromMonth) {
      query += '&from=' + encodeURIComponent(this.filters.fromMonth)
    }
    if (this.filters.toMonth) {
      query += '&to=' + encodeURIComponent(this.filters.toMonth)
    }
    if (this.filters.settlementStatus) {
      let value = ''
      switch (this.filters.settlementStatus) {
        case 'settled':
          value = '1'
          break
        case 'not-settled':
          value = '0'
          break
      }
      query += '&isSettled=' + value
    }
    this.isLoading = true
    this.$axios
      .get('/v4/sysop/billing/basis-per-month' + query)
      .then((response) => {
        for (let i = 0; i < response.data.data.length; i++) {
          response.data.data[i].isSelected = false
          response.data.data[i].expenseStatus = ''
        }
        this.data = response.data.data
        this.loadExpenses()
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Could not load data')
        this.isLoading = false
      })
  }

  private loadExpenses() {
    // todo: when load-expenses takes period params, sync it up with the actual periods we need info about
    this.$axios
      .get('/v4/sysop/billing/expenses/per-month?resellerId=' + this.resellerId)
      .then((response) => {
        console.log(response.data.data)
        for (let j = 0; j < this.data.length; j++) {
          const batch = this.data[j]
          for (let i = 0; i < response.data.data.length; i++) {
            const expense = response.data.data[i]
            if (batch.year === expense.year && batch.month === expense.month) {
              batch.expenseStatus = expense.status || ''
              break
            }
          }
        }
        this.isLoading = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error loading expenses per month')
        this.isLoading = false
      })
  }

  private formatAmount(amount): string {
    return formatAmount(amount)
  }

  @Watch('select')
  private onSelectChange(value) {
    const action = value.split('-')[0]
    for (let i = 0; i < this.data.length; i++) {
      switch (action) {
        case 'all':
          this.data[i].isSelected = true
          break
        case 'none':
          this.data[i].isSelected = false
          break
        case 'invert':
          this.data[i].isSelected = !this.data[i].isSelected
          break
      }
    }
  }

  private get selection() {
    const selection = []
    for (let i = 0; i < this.data.length; i++) {
      const d = this.data[i]
      if (d.isSelected) {
        selection.push({
          resellerId: d.resellerId,
          year: d.year,
          month: d.month,
        })
      }
    }
    return selection
  }

  @Watch('selection')
  private onSelectionChanged(selection) {
    this.$emit('selection', selection)
  }

  private getSettlementStatus(item): string {
    const diffFixed = Math.abs(parseFloat(item.fixedAmount) - parseFloat(item.fixedSettlementAmount))
    const diffDynamic = Math.abs(parseFloat(item.dynamicAmount) - parseFloat(item.dynamicSettlementAmount))
    const isFullySettled = diffFixed < 0.001 && diffDynamic < 0.001
    const isTodo = parseFloat(item.fixedSettlementAmount) === 0.0 && parseFloat(item.dynamicSettlementAmount) === 0.0
    return isFullySettled ? 'Ready' : isTodo ? 'Todo' : 'Partial'
  }

  private getSettlementClass(amount, settledAmount): string {
    const diff = Math.abs(parseFloat(amount) - parseFloat(settledAmount))
    if (diff < 0.001) {
      return 'status-ok'
    } else {
      return 'status-error'
    }
  }

  private getSettlementDetailsLink(settlementId) {
    return {
      name: 'Sysop/Billing/Settlement',
      params: {
        id: settlementId + '',
      },
    }
  }

  private getSettlementsLink(item) {
    return {
      name: 'Sysop/Billing',
      query: {
        tab: 'settlement-history',
        fromMonth: item.year + '-' + (item.month > 9 ? item.month : '0' + item.month),
        toMonth: item.year + '-' + (item.month > 9 ? item.month : '0' + item.month),
        submit: new Date().getTime() + '',
        resellerId: this.resellerId,
      },
    }
  }

  private getExpenseLink(item) {
    return {
      name: 'Sysop/Billing',
      query: {
        tab: 'settlement-expenses',
        resellerId: this.resellerId,
        year: item.year,
        month: item.month,
        openFromParams: '1',
      },
    }
  }
}
