














































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import BillingFilterModel from '@/components/sysop/billing/BillingFilterModel'
import { vxm } from '@/store'
import { formatAmount, getStatusClass } from '@/utils/billingUtils'

@Component
export default class BillingTableMonths extends Vue {
  @Prop()
  private filters: BillingFilterModel

  @Prop()
  private submit: string

  @Prop()
  private canGenerate: boolean

  @Prop({ required: true })
  private resellerId: number

  private isLoading = false
  private data = []
  private sums = {
    fixedAmount: 0,
    dynamicAmount: 0,
    totalAmount: 0,
    invoiceCreatedAmount: 0,
  }

  private created() {
    this.onSubmitChange()
  }

  @Watch('submit')
  private onSubmitChange() {
    this.data = []
    if (!this.submit) {
      return
    }
    this.load()
  }

  private load() {
    let query = '?resellerId=' + this.resellerId
    if (this.filters.fromMonth) {
      query += '&from=' + encodeURIComponent(this.filters.fromMonth)
    }
    if (this.filters.toMonth) {
      query += '&to=' + encodeURIComponent(this.filters.toMonth)
    }
    if (this.filters.invoiceStatus) {
      let value = ''
      switch (this.filters.invoiceStatus) {
        case 'invoiced':
          value = '1'
          break
        case 'not-invoiced':
          value = '0'
          break
      }
      query += '&isInvoiceCreated=' + value
    }
    this.isLoading = true
    this.$axios
      .get('/v4/sysop/billing/basis-per-month' + query)
      .then((response) => {
        for (const key in this.sums) {
          this.sums[key] = 0
        }
        for (let i = 0; i < response.data.data.length; i++) {
          response.data.data[i].isSelectedFixed = false
          response.data.data[i].isSelectedDynamic = false
          for (const key in this.sums) {
            this.sums[key] += parseFloat(response.data.data[i][key])
          }
        }
        this.data = response.data.data
        this.isLoading = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Could not load data')
        this.isLoading = false
      })
  }

  private getDetailsUrl(item) {
    const month = parseInt(item.month) > 9 ? item.month : '0' + item.month
    const yearMonth = item.year + '-' + month
    return {
      name: 'Sysop/Billing',
      query: {
        tab: 'month',
        resellerId: this.resellerId,
        fromMonth: yearMonth,
        toMonth: yearMonth,
        submit: '1',
      },
    }
  }

  private getStatusClass(item) {
    return getStatusClass(item)
  }

  // todo: optionally show decimals, and proper rounding
  private formatAmount(amount): string {
    return formatAmount(amount)
  }

  private setSelection(type, value) {
    const key = type === 'fixed' ? 'isSelectedFixed' : 'isSelectedDynamic'
    for (let i = 0; i < this.data.length; i++) {
      this.data[i][key] = value === -1 ? !this.data[i][key] : !!value
    }
  }

  private get selection() {
    const selection = []
    for (let i = 0; i < this.data.length; i++) {
      const d = this.data[i]
      if (d.isSelectedFixed) {
        selection.push({
          resellerId: d.resellerId,
          year: d.year,
          month: d.month,
          subscriptionTypeCategory: 'Fixed',
        })
      }
      if (d.isSelectedDynamic) {
        selection.push({
          resellerId: d.resellerId,
          year: d.year,
          month: d.month,
          subscriptionTypeCategory: 'Dynamic',
        })
      }
    }
    return selection
  }

  @Watch('selection')
  private onSelectionChanged(selection) {
    this.$emit('selection', selection)
  }
}
