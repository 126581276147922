export default class BillingFilterModel {
  public fromMonth = ''
  public toMonth = ''
  public invoiceStatus = ''
  public sendStatus = ''
  public settlementStatus = ''
  public settlementInvoicedStatus = ''
  public payer: number = null
  public site: number = null
  public product: number = null
  public typeOfCost = ''
  public batch: number = null
  public invoiceId = ''
  public settlementId: number = null

  public reset(): void {
    this.fromMonth = ''
    this.toMonth = ''
    this.invoiceStatus = ''
    this.sendStatus = ''
    this.settlementStatus = ''
    this.settlementInvoicedStatus = ''
    this.payer = null
    this.site = null
    this.product = null
    this.typeOfCost = ''
    this.batch = null
    this.invoiceId = ''
    this.settlementId = null
  }

  public populate(data: Record<string, unknown>) {
    this.fromMonth = (data.fromMonth as string) || ''
    this.toMonth = (data.toMonth as string) || ''
    this.invoiceStatus = (data.invoiceStatus as string) || ''
    this.sendStatus = (data.sendStatus as string) || ''
    this.settlementStatus = (data.settlementStatus as string) || ''
    this.settlementInvoicedStatus = (data.settlementInvoicedStatus as string) || ''
    this.payer = data.payer ? parseInt(data.payer as string) : null
    this.site = data.site ? parseInt(data.site as string) : null
    this.product = data.product ? parseInt(data.product as string) : null
    this.typeOfCost = (data.typeOfCost as string) || ''
    this.batch = data.batch ? parseInt(data.batch as string) : null
    this.invoiceId = (data.invoiceId as string) || ''
    this.settlementId = data.settlementId ? parseInt(data.settlementId as string) : null
  }
}
